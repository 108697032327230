<template>
  <Popover
    ref="coachmark"
    tag="div"
    class="animate-float animate-iterations-3 align-left relative"
    :visible="true"
    :placement="position"
    :popover-class="`${popoverClasses} shadow-md w-72 animate-float animate-iterations-3`"
    :target="target"
    :arrow-size="16"
    :portal-name="portalName"
  >
    <template #popover>
      <slot></slot>
      <div class="flex flex-row justify-end">
        <LzButton size="small" tabindex="0" @click="$emit('close')">{{ t('coachmark.close') }}</LzButton>
      </div>
    </template>
  </Popover>
</template>

<script setup>
import { ref } from 'vue'
import LzButton from './LzButton'
import { components } from 'vue_features/shared/i18n'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import Popover, { POSITIONS, DEFAULT_PORTAL } from './Popover'
import { useI18n } from 'vue-i18n'

defineProps({
  target: {
    default: null,
    validator: (val) =>
      val === null ||
      typeof val === 'string' ||
      val instanceof HTMLElement ||
      (val && val.constructor.name === 'VueComponent'),
  },
  position: {
    type: String,
    default: 'bottom',
    validator: (val) => POSITIONS.includes(val),
  },
  portalName: {
    type: String,
    default: DEFAULT_PORTAL,
  },
  popoverClasses: {
    type: String,
    default: '',
  },
})

const coachmark = ref(null)
const { t } = useI18n({ messages: pickLocaleNamespace(components, 'coachmark') })
</script>
