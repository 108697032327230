<template>
  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label | Component has been added to plugin allowlist so implementation must have proper for/id attributes-->
  <input ref="checkRef" type="checkbox" :checked="checked" :disabled="disabled" class="h-4 w-4" />
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'Checkbox',
  props: {
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    indeterminate: { type: Boolean, default: false },
  },
  setup(props) {
    const checkRef = ref(null)
    onMounted(() => {
      checkRef.value.indeterminate = props.indeterminate
    })
    return { checkRef }
  },
}
</script>
